import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SystemConstants } from "../common/system.constants";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { Observable } from "rxjs";
// import { retry,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class DataService {
  //protected apiURLHCOut = SystemConstants.BASE_API_AIH_OUT;
  //protected apiURLHC = SystemConstants.BASE_API_NEW;
  protected apiURL = SystemConstants.BASE_API_IDP;
  protected apiURL_AI = SystemConstants.BASE_API_AI;
  protected apiURLZero = SystemConstants.BASE_API_PROJECT;

  private headers: Headers;

  constructor(private http: HttpClient, public _authService: AuthService) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
  }

  getEndPointsAPI(url) {
    return `${this.apiURL}${url}`;
  }

  getEndPointsAPI_AI(url) {
    return `${this.apiURL_AI}${url}`;
  }

  getEndPointsAPIProject(url) {
    return `${this.apiURLZero}${url}`;
  }

  post(url: any, params: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.post(apiUrl, params, {
      headers: reqHeader,
    });
  }

  put(url: any, params: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.put(apiUrl, params, {
      headers: reqHeader,
    });
  }

  get(url: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
    });
  }

  delete(url: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.delete(apiUrl, {
      headers: reqHeader,
    });
  }

  postOut(url: any, params: any, reqHeader: any) {
    const apiUrl = url;
    var _reqHeader = new HttpHeaders(reqHeader);
    return this.http.post(apiUrl, params, {
      headers: _reqHeader,
    });
  }

  postOut1(url: any, params: any) {
    const apiUrl = this.getEndPointsAPI(url);
    return this.http.post(apiUrl, params);
  }

  public handleError(error: any) {
    return error;
  }

  ////IDP//////
  getIDP(url: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
    });
  }

  getIDPs(url: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
    });
  }

  postIDPs(url: any, params: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.post(apiUrl, params, {
      headers: reqHeader,
    });
  }

  putIDPs(url: any, params: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.put(apiUrl, params, {
      headers: reqHeader,
    });
  }

  deleteIDPs(url: any) {
    const apiUrl = this.getEndPointsAPI(url);
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.delete(apiUrl, {
      headers: reqHeader,
    });
  }

  ////PROJECT/////
  getFile(url: any) {
    const apiUrl = this.getEndPointsAPIProject(url);
    var reqHeader = new HttpHeaders({
      'Accept': 'application/json',
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
      responseType: "arraybuffer"
    });
  }

  postFile(url: any, params: any) {
    const apiUrl = this.getEndPointsAPI_AI(url);
    var reqHeader = new HttpHeaders({
      'Accept': 'application/json',
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.post(apiUrl, params, {
      headers: reqHeader,
      responseType: "arraybuffer"
    });
  }

  getFileJ(url: any) {
    const apiUrl = this.getEndPointsAPIProject(url);
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
      responseType: "arraybuffer"
    });
  }

  getPro(url: any) {
    const apiUrl = this.getEndPointsAPIProject(url);
    var reqHeader = new HttpHeaders({
      'Content-Type':'application/json'
      // 'Authorization':
      //   "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
    });
  }

  getProJ(url: any) {
    const apiUrl = this.getEndPointsAPIProject(url);
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
    });
  }

  postPro(url: any, params: any) {
    const apiUrl = this.getEndPointsAPIProject(url);
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      // Authorization:
      //   "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.post(apiUrl, params, {
      headers: reqHeader,
    });
  }

  putPro(url: any, params: any) {
    const apiUrl = this.getEndPointsAPIProject(url);
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.put(apiUrl, params, {
      headers: reqHeader,
    });
  }

  deletePro(url: any) {
    const apiUrl = this.getEndPointsAPIProject(url);
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.delete(apiUrl, {
      headers: reqHeader,
    });
  }

  ///////GENERAL///////////////////
  getJ(api: any, url: any) {
    const apiUrl = `${url}${api}`;
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
    });
  }

  getJa(api: any, url: any) {
    const apiUrl = `${url}${api}`;
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
    });
    //console.log(apiUrl,reqHeader);
    return this.http.get(apiUrl, {
      headers: reqHeader,
    });
  }

  postJ(api: any, url: any, params: any) {
    const apiUrl = `${url}${api}`;
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    var res = this.http.post(apiUrl, params, {
      headers: reqHeader,
    });
    return res;
  }

  postJa(api: any, url: any, params: any) {
    const apiUrl = `${url}${api}`;
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
    });
    //console.log(apiUrl,reqHeader);
    return this.http.post(apiUrl, params, {
      headers: reqHeader,
    });
  }

  putJ(api: any, url: any, params: any) {
    const apiUrl = `${url}${api}`;
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.put(apiUrl, params, {
      headers: reqHeader,
    });
  }
  deleteJ(api: any, url: any) {
    const apiUrl = `${url}${api}`;
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization:
        "Bearer " + this._authService.getLoggedInUser().access_token,
    });
    //console.log(apiUrl,reqHeader);
    return this.http.delete(apiUrl, {
      headers: reqHeader,
    });
  }

  //APP
  postApp(url: any, params: any) {
    const apiUrl = 'https://api-erm.medicalsaigon.com/' + url;
    var reqHeader = new HttpHeaders({
    });
    //console.log(apiUrl,reqHeader);
    return this.http.post(apiUrl, params, {
      headers: reqHeader,
    });
  }

  postMail(url: any, params: any) {
    const apiUrl = 'https://email-gateway.medicalsaigon.com/' + url;
    var reqHeader = new HttpHeaders({
    //  "Content-Type": "multipart/form-data"
    });
    //console.log(apiUrl,reqHeader);
    return this.http.post(apiUrl, params, {
      headers: reqHeader,
    });
  }
}
